import React, { useState, useContext, useEffect, useRef } from "react";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { useHistory, Redirect } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Card, Button, CardActions, Grid, CardHeader } from "@mui/material";

var ReactGA = require("react-ga");
const handleEvents = (param) => {
  console.log(param);

  ReactGA.event({
    category: "Button",
    action: "Click",
    label: param,
  });
};

const Home = () => {
  const title = "My Accounts";
  const history = useHistory();

  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const mountedRef = useRef(true);

  const getAccounts = () => {
    setLoading(true);
    let records = [];
    const accountsRef = FirebaseAuth.firestore().collection("accounts");
    let query = accountsRef.where(
      "access",
      "array-contains",
      FirebaseAuth.auth().currentUser.uid
    );
    query.get().then((accountSnapshots) => {
      if (!mountedRef.current) return null;
      accountSnapshots.forEach((account) => {
        // console.log("ACCOUNT", account.id);
        records.push({
          id: account.id,
          name: account.data().name,
          credits: account?.data()?.credits,
          subscriptionStatus: account.data().subscriptionStatus,
        });
      });
      setAccounts(records);
      // console.log("ACONT DETAIL", records);
      setLoading(false);
    });
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    getAccounts();
  }, [setBreadcrumb]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      {accounts.length > 0 ? (
        <>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "right",
            }}
          >
            <Button
              onClick={(e) => {
                handleEvents("Add New account");
                history.push("/new-account");
              }}
              color="primary"
              variant="contained"
            >
              <i className="fa fa-plus"></i> Add Account
            </Button>
          </div>

          <Grid container spacing={3}>
            {accounts.map((account, i) => (
              <Grid container item xs={12} md={3} key={i}>
                <Card key={account.id} style={{ width: "100%" }}>
                  <CardHeader
                    // action={<p>Credits: {account.credits}</p>}
                    title={account.name}
                  />
                  <CardActions>
                    {account.subscriptionStatus ? (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() =>
                          history.push("/account/" + account.id + "/home")
                        }
                      >
                        Account Overview
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        onClick={() =>
                          history.push(
                            "/account/" + account.id + "/billing/plan"
                          )
                        }
                      >
                        Activate the account
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          {loading ? (
            <Loader text="loading accounts..."></Loader>
          ) : (
            <Redirect to="/new-account"></Redirect>
          )}
        </>
      )}
    </>
  );
};

export default Home;
