import React from 'react';


function Base() {
    return (
        <div>
            This is a base component
        </div>
    );
}

export default Base;