import React from "react";
import { Box, Container } from "@mui/material"


const PublicTemplate = ({ children }) => {
    return (
		<Box style={{backgroundCOlor: 'white'}}>

	
					{children}
	
	
		</Box>
    )
}

export default PublicTemplate;